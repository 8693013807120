import { expValEquals } from '@confluence/feature-experiments';
import { useSessionData } from '@confluence/session-data';

//  Eligibility hook for suggested comment reply prompts experiment: https://hello.atlassian.net/wiki/spaces/Comments/pages/3579415601/Project+Poster+EXP+Suggested+Comment+Prompts+for+Inline+Comment+Replies
export const useGetCommentReplyPromptsEligibility = (): boolean => {
	const { isAdminHubAIEnabled } = useSessionData();

	return (
		isAdminHubAIEnabled &&
		expValEquals<string>('cc_suggested_inline_comment_replies', 'cohort', 'test')
	);
};
