import { createStore, createHook } from 'react-sweet-state';
import type { StoreActionApi } from 'react-sweet-state';

interface AnnotationLoadingState {
	annotationId: string;
	isLoaded: boolean;
}

export type CommentsDataState = {
	orderedActiveAnnotationIdList: AnnotationLoadingState[];
};

export const initialState: CommentsDataState = {
	orderedActiveAnnotationIdList: [],
};

export const actions = {
	setOrderedActiveAnnotationIdList:
		(newCommentsList: string[]) =>
		({ setState }: StoreActionApi<CommentsDataState>) => {
			setState({
				orderedActiveAnnotationIdList: newCommentsList.map((annotationId: string) => ({
					annotationId,
					isLoaded: false,
				})),
			});
		},
};

export const CommentsDataStore = createStore({
	initialState,
	actions,
	name: 'CommentsDataStore',
});

export const useCommentsData = createHook(CommentsDataStore);
