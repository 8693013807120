import gql from 'graphql-tag';

export const CommentButtonRefetchQuery = gql`
	query CommentButtonRefetchQuery($pageId: ID!) {
		comments(pageId: $pageId, type: UNRESOLVED, depth: ALL) {
			count
			nodes {
				id
				location {
					type
					... on InlineComment {
						inlineMarkerRef
					}
				}
			}
		}
	}
`;
