/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CommentDeletionLocation {
  EDITOR = "EDITOR",
  LIVE = "LIVE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInlineCommentMutation
// ====================================================

export interface DeleteInlineCommentMutation {
  deleteComment: boolean;
}

export interface DeleteInlineCommentMutationVariables {
  commentIdToDelete: string;
  deleteFrom?: CommentDeletionLocation | null;
}
