export const scrollCommentsPanel = ({
	containerId,
	commentMarkerRef,
}: {
	containerId: string;
	commentMarkerRef: string;
}) => {
	const container = document.querySelector(`[data-testid='${containerId}']`);
	if (container) {
		const element = document.querySelector(`[data-testid='${commentMarkerRef}']`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}
};
