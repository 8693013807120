export enum InlineCommentPubSubEvents {
	InlineCommentCreated = 'avi:confluence:created:inline_comment',
	RendererInlineCommentCreated = 'avi:confluence:created:renderer_inline_comment',
	EditorInlineCommentCreated = 'avi:confluence:created:editor_inline_comment',
	InlineCommentDeleted = 'avi:confluence:deleted:inline_comment',
	InlineCommentResolved = 'avi:confluence:resolved:inline_comment',
	InlineCommentUnresolved = 'avi:confluence:unresolved:inline_comment',
	InlineCommentUpdated = 'avi:confluence:updated:inline_comment',
	InlineCommentReattached = 'avi:confluence:reattach:inline_comment',
}

export enum PagePubSubEvents {
	PagePublished = 'avi:confluence:publish:page',
	BlogPublished = 'avi:confluence:publish:blogpost',
}
