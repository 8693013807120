import type { ApolloQueryResult } from 'apollo-client';

import { InlineCommentQuery } from '@confluence/inline-comments-queries';
import { getApolloClient } from '@confluence/graphql';

export const refreshCommentThread = (
	contentId: string,
	markerRef: string,
): Promise<ApolloQueryResult<any>> => {
	return getApolloClient().query({
		query: InlineCommentQuery,
		fetchPolicy: 'network-only',
		variables: {
			pageId: contentId,
			annotationId: markerRef,
			contentStatus: ['DRAFT', 'CURRENT'],
		},
	});
};
