import gql from 'graphql-tag';

// duplicated from @confluence/comment to avoid circular dependency that would result from importing it
export const WatchPageQuery = gql`
	query WatchPageQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				id
				currentUserIsWatching
			}
		}
	}
`;
