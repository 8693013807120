import { MediaSharedClassNames } from '@atlaskit/editor-common/styles';

export const getTargetNodeType = (annotationId?: string, isEditor?: boolean) => {
	if (!annotationId) {
		return 'unknown';
	}

	const elementWithAnnotationWrapper = document.querySelector(`[id="${annotationId}"]`);

	if (!elementWithAnnotationWrapper) {
		return 'unknown';
	}

	const captionNode = elementWithAnnotationWrapper.closest('[data-media-caption]');

	if (captionNode) {
		return 'caption';
	}

	if (isEditor) {
		const mediaNode = elementWithAnnotationWrapper.querySelector(
			`.${MediaSharedClassNames.MEDIA_CONTAINER}`,
		);

		if (mediaNode) {
			return 'media';
		}

		// If no node is detected, return "text" as the default node type for now
		return 'text';
	} else {
		const targetNode = elementWithAnnotationWrapper.querySelector('[data-node-type]');

		if (targetNode) {
			return (targetNode as HTMLElement).dataset.nodeType;
		}

		// If no node is detected, return "text" as the default node type for now
		return 'text';
	}
};
