import type { ARI, AVI, PubSubClient as AkPubSubClient } from '@atlaskit/pubsub';

import { getConfluenceContentAri } from '@confluence/content-types-utils';

export const eventToAvi = (eventName: string): AVI => `avi:confluence:${eventName}`;

// Note: export for testing purposes only
export const resourceIdToArray = (resourceId: any) => {
	if (Array.isArray(resourceId)) {
		return resourceId;
	} else {
		return [resourceId];
	}
};

export const getCurrentProtocolType = (client: any) => {
	return client?.currentProtocol?.getType();
};

export class PubSubClient {
	client: AkPubSubClient;
	cloudId: string;
	activationId: string;

	/**
	 * @param {object} client
	 * @param {string} cloudId
	 * @param {string} activationId
	 */
	constructor(client: AkPubSubClient, cloudId: string, activationId: string) {
		this.client = client;
		this.cloudId = cloudId;
		this.activationId = activationId;
	}

	/**
	 * Join a channel
	 * @param {array|string} resourceType - string represents the type of resource: page, comment, blogpost, space
	 * @param {array|string} resourceId - multiple or a resource id
	 */
	joinChannel = async (resourceType: any, resourceId: any) => {
		const resourceIds = resourceIdToArray(resourceId);
		const aris: ARI[] = resourceIds.map((id) =>
			getConfluenceContentAri({
				siteId: this.cloudId,
				resourceType,
				activationId: this.activationId,
				resourceId: id,
			}),
		);

		return this.client.join(aris).catch((error) => {
			throw new Error(
				`PubSub Web Client (${getCurrentProtocolType(this.client)}) joinChannel
        ${JSON.stringify(error, Object.getOwnPropertyNames(error))} aris: ${aris}`,
			);
		});
	};

	/**
	 * Leave a channel
	 * @param {array|string} resourceId - multiple or a resource id
	 */
	leaveChannel = async (resourceType: any, resourceId: any) => {
		const resourceIds = resourceIdToArray(resourceId);
		const aris = resourceIds.map((id) =>
			getConfluenceContentAri({
				siteId: this.cloudId,
				resourceType,
				activationId: this.activationId,
				resourceId: id,
			}),
		);

		return this.client.leave(aris).catch((error) => {
			throw new Error(
				`PubSub Web Client (${getCurrentProtocolType(this.client)}) leaveChannel
        ${JSON.stringify(error, Object.getOwnPropertyNames(error))} aris: ${aris}`,
			);
		});
	};

	/**
	 * Subscribe to an event being broadcast in channel
	 * @param {array|string} eventName - name of event to subscribe to
	 */
	subscribe = async (eventName: string, cb: any) => {
		return this.subscribeSync(eventName, cb);
	};

	subscribeSync = (eventName: string, cb: any) => {
		return this.client.on(eventName, cb);
	};

	/**
	 * Unsubscribe from an event being broadcast in channel
	 * @param {array|string} eventName - name of event to subscribe to
	 */
	unsubscribe = async (eventName: string, cb: any) => {
		return this.unsubscribeSync(eventName, cb);
	};

	unsubscribeSync = (eventName: string, cb: any) => {
		return this.client.off(eventName, cb);
	};
}
