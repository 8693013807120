import gql from 'graphql-tag';

import { inlineCommentFragment } from './InlineCommentFragment.fragment';

export const CreateInlineReplyMutation = gql`
	mutation CreateInlineReplyMutation($input: ReplyInlineCommentInput!) {
		replyInlineComment(input: $input) {
			...inlineCommentFragment
		}
	}

	${inlineCommentFragment}
`;
