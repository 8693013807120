import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook } from 'react-sweet-state';

import type { TopLevelComment } from '@confluence/inline-comments-queries';

export enum CurrentView {
	OPEN = 'OPEN',
	UNREAD = 'UNREAD',
	RESOLVED = 'RESOLVED',
	LOADING = 'LOADING',
	ERROR = 'ERROR',
}

export type CommentsPanelState = {
	commentsList: TopLevelComment[]; // stores parent comments
	currentView: CurrentView;
	totalCommentsCount: number;
	currentlySelectedCommentMarkerRef: string | undefined;
	isCommentsPanelOpen: boolean;
};

const initialState: CommentsPanelState = {
	commentsList: [],
	currentView: CurrentView.UNREAD, // defaults to unread
	totalCommentsCount: 0,
	currentlySelectedCommentMarkerRef: undefined,
	isCommentsPanelOpen: false,
};

// Actions for managing the comments state in the comments panel
const actions = {
	setCommentsList:
		(comments: TopLevelComment[]) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ commentsList: comments });
		},
	setCurrentView:
		(view: CurrentView) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ currentView: view });
		},
	setTotalCommentsCount:
		(count: number) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ totalCommentsCount: count });
		},
	setCurrentlySelectedCommentMarkerRef:
		(markerRef: string | undefined) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ currentlySelectedCommentMarkerRef: markerRef });
		},
	setIsCommentsPanelOpen:
		(isOpen: boolean) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ isCommentsPanelOpen: isOpen });
		},
};

const CommentsStore = createStore({
	initialState,
	actions,
	name: 'CommentsStore',
});

export const useCommentsPanel = createHook(CommentsStore);
