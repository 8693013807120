import type { FC } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import SectionMessage from '@atlaskit/section-message/section-message';

import {
	RESOLVE_INLINE_COMMENT_EXPERIENCE,
	DELETE_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { ErrorDisplay } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { getLogger } from '@confluence/logger';

import { parseError, getTranslatedError } from './inlineCommentsErrorUtils';
import { InlineCommentError } from './enum/InlineCommentError';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentErrorMessageContainer = styled.div({
	margin: `${token('space.150', '12px')} ${token('space.150', '12px')} 0`,
});

type CommentErrorMessageProps = {
	error: Error;
	action: 'resolve' | 'delete' | 'reopen';
	commentId?: string;
};

const logger = getLogger('inline-comments-common:error-message');

export const CommentErrorMessage: FC<CommentErrorMessageProps> = ({ error, action, commentId }) => {
	const [experienceName, setExperienceName] = useState('');
	const experienceTracker = useContext(ExperienceTrackerContext);
	const parsedError = parseError(error);

	const isKnownError =
		// WS-2629 A status code of 404 indicates a not found request (comment already removed) so we can handle it properly
		parsedError.statusCode === 404 ||
		// WS-2576 A status code of 400 for a delete indicates a bad request (attempted delete with children) so we can handle it properly
		(action === 'delete' && parsedError.statusCode === 400) ||
		// WS-2579 A status code of 403 for a delete with a message matching no anonymous access indicates a mismatch between FE and BE perms so show the user the warning
		(parsedError.statusCode === 403 &&
			parsedError.message === InlineCommentError.NO_ANONYMOUS_ACCESS) ||
		// WS-2629 A status code of 500 for a resolve and a message matching the resolve deleted comment message is a known error so handle it properly
		(action === 'resolve' &&
			parsedError.statusCode === 500 &&
			parsedError.message ===
				InlineCommentError.CANNOT_RESOLVE_DELETED_COMMENT.replace('%d', commentId || ''));

	useEffect(() => {
		switch (action) {
			case 'resolve':
				setExperienceName(RESOLVE_INLINE_COMMENT_EXPERIENCE);
				break;
			case 'delete':
				setExperienceName(DELETE_INLINE_COMMENT_EXPERIENCE);
				break;
			case 'reopen':
				// TODO: Add re-open experience?
				setExperienceName('');
				break;
			default:
				logger.error`Invalid action: ${action}`;
				setExperienceName('');
				break;
		}
	}, [action]);

	useEffect(() => {
		if (isKnownError) {
			markErrorAsHandled(error);
		} else {
			// Stop experiences that are ongoing only when an unknown error happens
			if (experienceName.length) {
				experienceTracker.stopOnError({
					name: experienceName,
					error,
				});
			}
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [error, isKnownError]);

	const children = (
		<CommentErrorMessageContainer>
			<SectionMessage appearance="warning" testId="editor-inline-message">
				{isKnownError ? (
					<FormattedMessage {...getTranslatedError(parsedError.message, commentId)} />
				) : (
					<FormattedMessage
						id="inline-comments-common.editor.error.message"
						defaultMessage="Something went wrong when trying to {action} your comment. Please close the editor and try again."
						description="A message displayed to the user informing them something happened when saving, resolving or unresolving their comment"
						values={{ action }}
					/>
				)}
			</SectionMessage>
		</CommentErrorMessageContainer>
	);

	return isKnownError ? children : <ErrorDisplay error={error}>{children}</ErrorDisplay>;
};
