import { getSessionData } from '@confluence/session-data';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';

import { PubSubClient } from './PubSubClient';

const CONFLUENCE_PRODUCT_KEY = 'CONFLUENCE';

// Where the pubsub service lives
const PUBSUB_HOST = '/gateway/api/pubsub';

let pubsubClientPromise: Promise<PubSubClient>;

const getPubSubDevUrl = (): URL | undefined => {
	if (window.location.hostname === 'localhost') {
		// In dev mode this allows the local dev proxy to use the WebSocket host
		// that matches the backend origin host. The __backendOriginHost is only
		// available with local dev server.
		const host = (window as any).__backendOriginHost;
		return new URL('/gateway/wss/fps', `wss://${host}`);
	}
	return undefined;
};

// Note: export for testing purposes only
export const createConfig = ({ url, analyticsClient }: { url: string; analyticsClient?: any }) => {
	return {
		product: CONFLUENCE_PRODUCT_KEY,
		url,
		apsProtocol: {
			enabled: true,
			url: getPubSubDevUrl(),
		},
		analyticsClient,
	};
};

/***
 *
 * Initializes and returns a promise with a new instance of the pubsub client (configured with the correct cloudId).
 * Note: export for testing purposes only
 *
 * @returns Promise
 */
export const initializePubSubClient = () => {
	return getSessionData().then(async ({ cloudId, activationId }) => {
		const analyticsClient = await getAnalyticsWebClient();
		const config = createConfig({
			url: PUBSUB_HOST,
			analyticsClient,
		});

		return import(/* webpackChunkName: "loadable-atlaskitpubsub" */ '@atlaskit/pubsub')
			.then((module) => {
				const Client = module.default;
				return new PubSubClient(new Client(config), cloudId, activationId);
			})
			.catch((error) => {
				throw new Error(
					`PubSub--Current Web Client ${error}, ${JSON.stringify({
						...config,
					})}`,
				);
			});
	});
};

/***
 *
 * Returns a promise that will resolve with an instance of the pubsub client
 * If PubSub client has not been initialized, then called initializePubSubClient only once
 *
 * The promise will only resolve after initializePubSubClient has been called.
 *
 * @returns Promise
 */
export const getPubSubClient = (): Promise<PubSubClient> => {
	if (pubsubClientPromise) {
		return pubsubClientPromise;
	}

	pubsubClientPromise = initializePubSubClient();

	return pubsubClientPromise;
};
