import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook } from 'react-sweet-state';

interface SuggestedCommentsState {
	commentThreadIdToLengthMap: Map<string, number>; // maps root comment id to the comment thread length
}

const initialState: SuggestedCommentsState = {
	commentThreadIdToLengthMap: new Map<string, number>(),
};

const actions = {
	updateSuggestedCommentIDMapLength:
		(commentId: string, newLength: number) =>
		({ setState, getState }: StoreActionApi<SuggestedCommentsState>) => {
			const { commentThreadIdToLengthMap } = getState();
			commentThreadIdToLengthMap.set(commentId, newLength);
			setState({ commentThreadIdToLengthMap });
		},
};

const SuggestedCommentsStore = createStore({
	initialState,
	actions,
	name: 'SuggestedCommentsStore',
});

export const useSuggestedComments = createHook(SuggestedCommentsStore);
