import gql from 'graphql-tag';

export const ResolveInlineCommentMutation = gql`
	mutation ResolveInlineCommentMutation($commentId: ID!, $resolved: Boolean!) {
		resolveInlineComment(commentId: $commentId, resolved: $resolved, dangling: false) {
			status
			resolveProperties {
				resolved
				resolvedUser
				resolvedByDangling
				resolvedFriendlyDate
				resolvedTime
			}
		}
	}
`;
