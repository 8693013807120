import { TYPES_WITH_ACTIVATIONID } from './contentTypeConstants';

type ConfluenceContentAriParameters = {
	siteId: string;
	resourceType: string;
	activationId: string;
	resourceId: string;
};

/**
 * Builds a registered ARI from the ARI registry. go/ari to learn more
 */
export const getConfluenceContentAri = (
	confluenceContentAriParameters: ConfluenceContentAriParameters,
): string => {
	const { siteId, resourceType, activationId, resourceId } = confluenceContentAriParameters;
	if (TYPES_WITH_ACTIVATIONID.includes(resourceType)) {
		return `ari:cloud:confluence:${siteId}:${resourceType}/activation/${activationId}/${resourceId}`;
	}

	return `ari:cloud:confluence:${siteId}:${resourceType}/${resourceId}`;
};
